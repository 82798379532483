<template>
  <div>
    <v-dialog v-model="manage_limit_dialog" width="500">
      <v-card>
        <v-card-title class="text-h6 grey lighten-2"> Manage Plan Limits </v-card-title>

        <v-card-text>
          <div style="padding: 20px">
            <p><strong>Current Plan: </strong>{{ shop_plans.package_name }}</p>
            <v-text-field
              type="number"
              name="product_upload_limit"
              v-model="shop_plans.product_upload_limit"
              class="w-full"
              label="Product Upload Limit (Feed)"
              outlined
              hide-details
              v-validate="'required'"
            ></v-text-field>
            <span class="red--text text-sm" v-if="errors.has('product_upload_limit')">{{
              errors.first("product_upload_limit")
            }}</span>

            <v-text-field
              type="number"
              v-model="shop_plans.shopify_upload_limit"
              class="w-full mt-5"
              label="Shopify Upload Limit"
              outlined
              hide-details
              v-validate="'required'"
              name="shopify_upload_limit"
            ></v-text-field>
            <span class="red--text text-sm" v-if="errors.has('shopify_upload_limit')">{{
              errors.first("shopify_upload_limit")
            }}</span>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="manage_limit_dialog = false"> CANCEL </v-btn>
          <v-btn color="primary" text @click="manageShopPlanLimits"> DONE </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="extend_trial_dialog" width="500">
      <v-card>
        <v-card-title class="text-h6 grey lighten-2"> Extend Trial Days </v-card-title>

        <v-card-text>
          <div style="padding: 20px">
            <v-text-field
              type="number"
              v-model="free_trial.trial_days"
              class="w-full"
              label="Trial Days"
              outlined
            ></v-text-field>
            <div style="margin-top: -20px !important">
              <small style="color: red"
                >NOTE: It will add the above entered days in existing trial days.</small
              >
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="extend_trial_dialog = false"> CANCEL </v-btn>
          <v-btn color="primary" text @click="processTrialExtend" :loading="is_trail_button_clicked" :disabled="is_trail_button_clicked">
            <v-progress-circular v-if="is_trail_button_clicked" indeterminate color="primary" size="20"></v-progress-circular>
            <span v-else>Done</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="full_sync_shop_dialog" width="500">
      <v-card>
        <v-card-title class="text-h6 grey lighten-2"> Full Sync Shop </v-card-title>
        <v-card-body class="ml-5">Are you sure you want to full sync shop?</v-card-body>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="full_sync_shop_dialog = false"> No </v-btn>
          <v-btn color="primary" text @click="fullSyncShop" :loading="is_trail_button_clicked" :disabled="is_trail_button_clicked">
            <v-progress-circular v-if="is_trail_button_clicked" indeterminate color="primary" size="20"></v-progress-circular>
            <span v-else>Yes</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="is_loading" class="text-center">
      <img
        :src="require('@/assets/images/misc/loading.gif')"
        alt=""
        width="100px"
        style="margin-top: 30vh !important"
      />
    </div>
    <v-container v-else>
      <v-row>
        <v-spacer></v-spacer>
         <v-btn
          color="success"
          :loading="is_reverse_sync_loading"
          @click="reverseSync(shop.id)"
          class="tet-white mr-2"
          small
          >Reverse Sync</v-btn
        >

        <v-btn
          color="secondary"
          @click="manage_limit_dialog = true"
          class="tet-white mr-2"
          small
          >Manage Limits</v-btn
        >
        <v-btn
          color="primary"
          @click="extend_trial_dialog = true"
          class="tet-white mr-2"
          small
          >Extend Trial</v-btn
        >
        <v-btn
          color="info"
          @click="full_sync_shop_dialog = true"
          class="tet-white mr-2"
          small
          >Full Sync Shop</v-btn
        >
      </v-row>
      <v-row>
        <v-col>
          <v-row class="match-height">
            <v-col cols="12" sm="3">
              <statistics-card-vertical
                color="green"
                :statistics="`${counts.shopify_products}`"
                stat-title="Total Shopify Products"
                subtitle="Total products in Shopify"
                :icon="icons.mdiCart"
              ></statistics-card-vertical>
            </v-col>

            <v-col cols="12" sm="3">
              <statistics-card-vertical
                color="primary"
                :statistics="`${counts.feeds}`"
                stat-title="Total Feeds Created"
                subtitle="Product Listing feeds created on system."
                :icon="icons.mdiCart"
              ></statistics-card-vertical>
            </v-col>

            <v-col cols="12" sm="3">
              <statistics-card-vertical
                color="green"
                :statistics="`${counts.uploaded_products}`"
                stat-title="Uploaded Products"
                subtitle="Uploaded/Linked products from the system"
                :icon="icons.mdiCheck"
              ></statistics-card-vertical>
            </v-col>

            <v-col cols="12" sm="3">
              <statistics-card-vertical
                color="red"
                :statistics="`${counts.upload_failed_products}`"
                stat-title="Upload Failed Products"
                subtitle="Product upload/update failed due to various reasons"
                :icon="icons.mdiAlert"
              ></statistics-card-vertical>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider class="mt-5 mb-5"></v-divider>
      <v-row>
        <v-col cols="12" sm="3">
          <v-checkbox
                class="mt-0"
                v-model="shop.is_store_optimized"
                label="Store Optimized"
                color="primary"
                @click="storeOptimized()"
                hide-details>
            </v-checkbox>
        </v-col>
    <v-col cols="12" sm="2">
          <v-select
            aria-readonly="true"
            v-model="shop.review.app_rating"
            :items="ratingOptions"
            label="Select App Rating"
            color="primary"
            hide-details
            dense
            @change="handleRatingChange"
          ></v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-rating
          v-model="shop.review.app_rating"
          background-color="orange"
          color="#f5bc42"
          dense
        ></v-rating>
    </v-col>

  </v-row>

      <v-row>
        <!-- If Shop has User or is not uninstalled -->
        <v-col cols="12" sm="4" v-if="shop.user !== null">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2"
              ><v-img
                max-height="30"
                max-width="30"
                :src="require('@/assets/images/avatars/1.png')"
              ></v-img>
              &nbsp;User Details</v-card-title
            >
            <v-divider></v-divider>
            <v-card-text v-if="shop.user">
              <p class="font-18">
                <strong>Name: </strong>{{ shop.user.name }} ({{ shop.user.id }})
              </p>
              <p class="font-18"><strong>Username: </strong>{{ shop.user.username }}</p>
              <p class="font-18"><strong>Email: </strong>{{ shop.user.email }}</p>
              <p class="font-18">
                <strong>Shopify Cus. Id: </strong>{{ shop.user.shopify_customer_id }}
              </p>
              <p class="font-18">
                <strong>Registered Date: </strong
                >{{ shop.user.registerDate | date_format }}
              </p>
              <p class="font-18">
                <strong>Last Visit: </strong
                >{{ shop.user.lastvisitDate | date_format("timeago") }}
              </p>
            </v-card-text>
            <v-card-text v-else>
              <div style="padding: 105px 0px" class="text-center">
                <h3>User details not found!!</h3>
              </div>
            </v-card-text>
          </v-card>

          <v-card style="margin-top: 40px;">
            <v-card-title class="text-h5 grey lighten-2">Rating/Review </v-card-title>
            <v-divider></v-divider>
            <v-card-text v-if="shop.review && !shop.review.noReview">
              <p class="font-18">
                <strong>Internal Rating: </strong>{{ shop.review.internal_rating == 0 ? "Cancelled" : shop.review.internal_rating}}
              </p>
              <p class="font-18"><strong>Internal Review: </strong>{{ shop.review.internal_review ? shop.review.internal_review : '' }}</p>
              <div style="display: flex;">
              <p class="font-18"><strong>App Rating: </strong></p>
                <v-rating
                  v-model="shop.review.app_rating"
                  background-color="orange"
                  color="#f5bc42"
                  dense
                ></v-rating>
            </div>
            </v-card-text>
            <v-card-text v-else>
              <div style="padding: 105px 0px" class="text-center">
                <h3>No Review added yet!!</h3>
              </div>
            </v-card-text>
          </v-card>
          
        </v-col>
            <!-- If Shop has been uninstalled -->
          <v-col cols="12" sm="4" v-if="shop.user == null && uninstalled_client !== null">
            <v-card>
              <v-card-title class="text-h5 grey lighten-2"
                ><v-img
                  max-height="30"
                  max-width="30"
                  :src="require('@/assets/images/avatars/2.png')"
                ></v-img>
                &nbsp;Uninstalled Shop Details</v-card-title
              >
              <v-divider></v-divider>
              <v-card-text v-if="uninstalled_client">
                <p class="font-18"><strong>Shop Name: </strong>{{ uninstalled_client.shop_name }}</p>
                <p class="font-18"><strong>Email: </strong>{{ uninstalled_client.shop_email }}</p>
                <p class="font-18">
                  <strong>Total Product on Feed: </strong>{{ uninstalled_client.total_product_on_feed }}
                </p>
                  <p class="font-18">
                  <strong>Total Uploaded Products: </strong>{{ uninstalled_client.total_uploaded_products }}
                </p>
                  <p class="font-18">
                  <strong>Failed Products: </strong>{{ uninstalled_client.failed_products }}
                </p>
                <p class="font-18">
                  <strong>Total Orders: </strong>{{ uninstalled_client.total_orders }}
                </p>
                <p class="font-18">
                  <strong>Success Orders: </strong>{{ uninstalled_client.success_orders }}
                </p>
                <p class="font-18">
                  <strong>Failed Orders: </strong>{{ uninstalled_client.failed_orders }}
                </p>
               
              </v-card-text>
              <v-card-text v-else>
                <div style="padding: 105px 0px" class="text-center">
                  <h3>Uninstalled Client not found!!</h3>
                </div>
              </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2"
              ><v-img
                max-height="30"
                max-width="30"
                :src="require('@/assets/images/logos/shopify.png')"
              ></v-img>
              &nbsp;Shopify Shop Details</v-card-title
            >
            <v-divider></v-divider>
            <v-card-text>
              <p class="font-18"><strong>Specifier: </strong>{{ shop.specifier }}</p>
              <p class="font-18"><strong>License Key: </strong>{{ shop.license_key }}</p>
              <p class="font-18"><strong>Token: </strong>{{ shop.token }}</p>
              <p class="font-18">
                <strong>Last Refreshed: </strong
                >{{ shop.last_feed_refresh == null ? 'NULL' : shop.last_feed_refresh }}
              </p>
              <p class="font-18">
                <strong>Package: </strong>{{ shop_plans.package_name }} ({{
                  shop.subscribed_package
                }})
              </p>

              <div v-if="shop.license_key.includes('Free')">
                <p class="font-18">
                  <strong>Is Free?: </strong> Yes
                </p>
              </div>

              <!-- Trial Condition starts Here -->
              <!-- Billing Activate 1 =  Trial, and 2 = after trail process -->
              <div v-if="shop.license_key.includes('Trial') && !shop.license_key.includes('Ext')">
                <p class="font-18">
                  <strong>Is Trial?: </strong> Yes
                </p>
                  <p class="font-18">
                  <strong>Trial Ends On: </strong> {{shop.trial_ends_on}}
                </p>
                <p class="font-18">
                  <!-- <strong>Trial Remaining Days: </strong> {{trialDaysRemaining()}} -->
                </p>
                
                <!--  Trial Extended Conditions-->
              </div>

               <div v-if="shop.license_key.includes('Ext')">
                <p class="font-18">
                  <strong>Is Trial Extended?: </strong> Yes
                </p>
                  <p class="font-18">
                  <strong>Trial Ends On: </strong> {{shop.trial_ends_on}}
                </p>
                  <p class="font-18">
                  <strong>Trial Remaining Days: </strong> {{remaining_trial_days}}
                </p>
              </div>

                <p class="font-18" v-if="!shop.license_key.includes('Ext') && !shop.license_key.includes('Trial')">
                  <strong>Is Trial?: </strong> No
                </p>
                <p class="font-18" v-if="shop.trail_extended_days != 0">
                  <strong>Days Extended (Not Accepted): </strong> {{shop.trail_extended_days}}
                </p>
            
              <!-- Trial Condition ends Here -->
     
              <p class="font-18">
                <strong>Recurring Charge: </strong>${{ shop.subscribed_plan }}
              </p>
              
              <p class="font-18">
                <strong>Version: </strong>
                {{ shop.shopify_version }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              <v-img
                max-height="35"
                max-width="35"
                :src="require('@/assets/images/logos/etsy.png')"
              ></v-img>
              &nbsp;Etsy Shop Details
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text v-if="shop.etsy_shop">
              <p class="font-18"><strong>Name: </strong>{{ shop.etsy_shop.name }}</p>
              <p class="font-18">
                <strong>Shop Owner: </strong>{{ shop.etsy_shop.shop_owner }}
              </p>
              <p class="font-18">
                <strong>Shop ID: </strong>{{ shop.etsy_shop.etsy_shop_id }}
              </p>
              <p class="font-18">
                <strong>Oauth Token: </strong>{{ shop.etsy_shop.oauth_token }}
              </p>
              <p class="font-18">
                <strong>Oauth Secret: </strong>{{ shop.etsy_shop.oauth_token_secret }}
              </p>
              <p class="font-18">
                <strong>Version: </strong>{{ shop.etsy_shop.oauth_refresh_token ? 'v3' : 'v2' }}
              </p>
            </v-card-text>

            <v-card-text v-else>
              <div style="padding: 105px 0px" class="text-center">
                <h3>Etsy Shop not Connected!!</h3>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
.font-18 {
  font-size: 18px;
}
</style>
<script>
import { mapActions } from "vuex";
import { mdiCart, mdiFaceProfile, mdiCheck, mdiAlert } from "@mdi/js";
import StatisticsCardVertical from "@/components/statistics-card/StatisticsCardVertical.vue";
export default {
  name: "ShopDetail",
  data() {
    return {
      is_loading: false,
      is_reverse_sync_loading: false,
      is_trail_button_clicked: false,
      shop_id: null,
      selectedRating: 0,
      shop: {
        is_store_optimized: false,
        etsy_shop: {},
        review : {
          app_rating: 0,
          internal_rating: 0,
          internal_review: "",
        }
      },
      ratingOptions: [
      { value: 0, text: 'No App Rating' },
      { value: 1, text: '1 Star' },
      { value: 2, text: '2 Stars' },
      { value: 3, text: '3 Stars' },
      { value: 4, text: '4 Stars' },
      { value: 5, text: '5 Stars' }
    ],
      uninstalled_client: {},
      icons: {
        mdiFaceProfile: mdiFaceProfile,
        mdiCart: mdiCart,
        mdiCheck: mdiCheck,
        mdiAlert: mdiAlert,
      },
      counts: {},
      extend_trial_dialog: false,
      full_sync_shop_dialog: false,
      free_trial: {
        trial_days: "",
        apply_plan_limit: false,
      },

      //LIMITS
      manage_limit_dialog: false,
      shop_plans: {},
      remaining_trial_days: 0,
    };
  },
  mounted() {
    this.shop_id = this.$route.params.shop_id;
    this.fetchShopDetail();
  },
  components: {
    StatisticsCardVertical,
  },
  methods: {
    ...mapActions(["LoginAsClient"]),
    async fetchShopDetail() {
      try {
        this.is_loading = true;
        let response = await axios.get(`admin/shops/${this.shop_id}/get-detail`);
        if (response.data.success) {
          this.shop = response.data.shop;
          if (response.data.shop.review == null) {
            this.shop.review = {
              app_rating: 0,
              internal_rating: 0,
              internal_review: "",
              noReview: true
            };
          }
          this.uninstalled_client= response.data.uninstalled_client;
          this.counts = response.data.counts;
          this.shop_plans = JSON.parse(response.data.shop.plan_limits);
          this.trialDaysRemaining();
        }
      } catch (e) {
      } finally {
        this.is_loading = false;
      }
    },

    async loginAsClient() {
      let resposne = await this.LoginAsClient(this.shop);
      if (resposne.success != true) {
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Email or password mismatch!",
        });
      } else {
        window.location = "/dashboard";
      }
    },

    async trialDaysRemaining() {
        const start = new Date(new Date().toISOString().substr(0, 10));
        const end = new Date(this.shop.trial_ends_on);
        const timeDifference = end.getTime() - start.getTime();
        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
        this.remaining_trial_days = daysDifference
    },

    async processTrialExtend() {
      try {
        console.log("Clicked extend")
        this.is_trail_button_clicked = true;
        
        if (!this.free_trial.trial_days) {
          this.$swal.fire({
            icon: "error",
            title: "Error",
            text: "Please Enter Trial Days",
          });
          return;
        }
        let response = await axios.post(
          `admin/shops/${this.shop_id}/extend-trial`,
          this.free_trial
        );
        if (response.data.success) {
          this.$swal.fire({
            icon: "success",
            title: "DONE",
            html: `Trial extended for ${this.free_trial.trial_days} days. <br> <br> The Trial days of the shop is automatically extended in Shopify.`,
          });

          this.free_trial.trial_days = "";
          this.extend_trial_dialog = false;
          this.is_trail_button_clicked = false;
          this.fetchShopDetail();
        }
      } catch (e) {}
    },

    async reverseSync(shop_id)
    {
      this.is_reverse_sync_loading = true;
      let response = await axios.get(
          `/set-reverse-sync-queue?shop_id=${shop_id}`
        );
        if (response.data.success) {
          this.$swal.fire({
            icon: "success",
            title: "DONE",
            html: `Reverse Sync Successful! We are fetching Etsy Products Now!`,
          });
        } 
        else{
          this.$swal.fire({
            icon: "error",
            title: "Error",
            text: "Something went wrong, while setting shop to the Reverse Sync Process!",
          });
        }
        this.is_reverse_sync_loading = false;
    },

     async fullSyncShop() {
      try {        
        let baseURL = process.env.NODE_ENV == 'development'
          ? 'https://dikshant-joomla.loca.lt'
          : 'https://rapid.shoppingcartproductfeed.com';

        let response = await axios.get(`admin/shops/${this.shop_id}/full-sync?base_url=${baseURL}`);
        if(response.data.includes('true')){
            this.full_sync_shop_dialog=false;
            this.$swal.fire({
              icon: "success",
              title: "DONE",
              html: `Shop successfully added for Full Sync`,
            });
          this.fetchShopDetail();
        }
        else{
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Shop could not be Full synced!",
          });
        }
      } catch (e) {}
    },

    async storeOptimized(){
      let response = await axios.get(`admin/shops/${this.shop_id}/optimize-shop`);
      if (response.data.success) {
          this.$swal.fire({
              icon: "success",
              title: "DONE",
              html: `Optimized status changed Successfully!`,
          });
      }
    },

    async handleRatingChange() {
      try {
        const newRating = this.shop.review.app_rating;
        const formData = {
          app_rating: newRating,
        };

        const response = await axios.post(`admin/shops/${this.shop_id}/update-rating`, (formData));
        if (!response.data.success) {
          this.$swal.fire({
              icon: "error",
              title: "Failed",
              html: `Error while updating rating!`,
          });
          return;
        }

        this.$swal.fire({
              icon: "success",
              title: "DONE",
              html: `Rating Updated Successfully!`,
          });
        this.fetchShopDetail();
      } catch (error) {
        console.error('Error updating rating:', error);
      }
    },

    async manageShopPlanLimits() {
      let result = await this.$validator.validateAll();
      if (!result) {
        return;
      }

      try {
        let response = await axios.post(
          `admin/shops/${this.shop_id}/manage-shop-plans`,
          this.shop_plans
        );
        if (response.data.success) {
          this.$swal.fire({
            icon: "success",
            title: "DONE",
            html: `Plan limit successfully updated`,
          });

          this.manage_limit_dialog = false;
          this.fetchShopDetail();
        }
      } catch (e) {}
    },
  },
};
</script>
